import React from "react";

export const DefaultDropdown = (props: {
    name: any;
    value: any;
    onChange: any;
    options: any;
    required: any;
    readOnly: any;
}) => {
    const { name, value, onChange, options, required, readOnly } = props;
    return (
        <div>
            {name && (
                <label className="text-left block uppercase tracking-wide text-atroposPrimary text-xs font-bold mb-2">
                    {!required ? (
                        name
                    ) : (
                        <>
                            {name} <span className="text-red-600"> *</span>
                        </>
                    )}{" "}
                </label>
            )}
            <div className="relative">
                <select
                    className="block min-w-48 appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    defaultValue={""}
                    value={value}
                    readOnly={readOnly || false}
                    onChange={onChange}
                >
                    {options.map((option) => {
                        if (
                            typeof option === "object" &&
                            option !== null &&
                            !Array.isArray(option) &&
                            "value" in option
                        ) {
                            return (
                                <option
                                    key={option.value}
                                    value={option.value}
                                    readOnly={readOnly || false}
                                >
                                    {option.label}
                                </option>
                            );
                        }
                        return (
                            <option
                                key={option}
                                value={option}
                                readOnly={readOnly || false}
                            >
                                {option}
                            </option>
                        );
                    })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </div>
            </div>
        </div>
    );
};
