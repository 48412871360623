import React, { useState } from "react";

export const CRGInvoicing = () => {
    const data = [
        {
            _id: 12456,
            rg: "2014/1289/A",
            portalis: "xxxx-x-xxx-xxxx",
            name: "M.MAGNETTE Tuteur",
            wealth: "25 000 €",
            price: "120.00 €",
        },
    ];

    const [selectedCategory, setSelectedCategory] = useState(""); // Filtre de catégorie
    const filteredData = selectedCategory
        ? data.filter((item) => item.category === selectedCategory)
        : data;

    const [currentPage, setCurrentPage] = useState(1); // Page actuelle
    const [itemsPerPage] = useState(10); // Nombre de lignes par page

    // Calculer le nombre total de pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Déterminer les éléments à afficher pour la page actuelle
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1); // Reset page to 1 when filtering
    };

    // Récupérer les catégories uniques pour le filtre
    const uniqueCategories = [...new Set(data.map((item) => item.category))];

    return (
        <div className="bg-gray-50 dark:bg-gray-900">
            <div className="container max-w-6xl px-5 mx-auto mt-2">
                <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="p-5 bg-white rounded shadow-sm">
                        <div className="text-base text-gray-400 ">
                            CA Total{" "}
                        </div>
                        <div className="flex items-center pt-1">
                            <div className="text-2xl font-bold text-gray-900 ">
                                €9850.90 HT
                            </div>
                        </div>
                    </div>
                    <div className="p-5 bg-white rounded shadow-sm">
                        <div className="text-base text-gray-400 ">
                            CA sur 30 jours
                        </div>
                        <div className="flex items-center pt-1">
                            <div className="text-2xl font-bold text-gray-900 ">
                                €250.00 HT
                            </div>
                        </div>
                    </div>
                    <div className="p-5 bg-white rounded shadow-sm">
                        <div className="text-base text-gray-400 ">
                            # de CRG vérifié
                        </div>
                        <div className="flex items-center pt-1">
                            <div className="text-2xl font-bold text-gray-900 ">
                                7520
                            </div>
                        </div>
                    </div>
                    <div className="p-5 bg-white rounded shadow-sm">
                        <div className="text-base text-gray-400 ">
                            # de dépots actifs
                        </div>
                        <div className="flex items-center pt-1">
                            <div className="text-2xl font-bold text-gray-900 ">
                                1375
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Identifiant
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Numéro de RG
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Portalis
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Représentant
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Patrimoine
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Facture TTC
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr
                                    key={index}
                                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                >
                                    <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        {item._id}
                                    </th>
                                    <td className="px-6 py-4">{item.rg}</td>
                                    <td className="px-6 py-4">
                                        {item.portalis}
                                    </td>
                                    <td className="px-6 py-4">{item.name}</td>
                                    <td className="px-6 py-4">{item.wealth}</td>
                                    <td className="px-6 py-4">{item.price}</td>

                                    <td className="px-6 py-4">
                                        <a
                                            href="#"
                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2"
                                        >
                                            Télécharger
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            className={`px-4 py-2 mx-1 text-sm font-medium text-white ${
                                currentPage === index + 1
                                    ? "bg-blue-500"
                                    : "bg-gray-700"
                            } rounded-md hover:bg-blue-700`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
