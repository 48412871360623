import React, { useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../../../../_shared/Button";
import { DefaultDropdown } from "../../../../_shared/Dropdown";
import { InputForm } from "../../../../_shared/InputField";

export const CreateControle = () => {
    const history = useHistory();
    const [tribunal, setTribunal] = useState(null);
    const [numeroRG, setNumeroRG] = useState("");
    const [portalis, setPortalis] = useState("");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [firstLastName, setFirstLastName] = useState("");
    const [name, setName] = useState("");

    const [agentName, setAgentName] = useState("");
    const [agentMail, setAgentMail] = useState("");

    const [subRogeName, setSubRogeName] = useState("");
    const [subRogeMail, setSubRogeMail] = useState("");
    return (
        <div className="p-4">
            <Button
                type={"empty"}
                name={"Retour"}
                action={() => {
                    history.push("/list_controles");
                }}
            />
            <div className="mt-2">
                <DefaultDropdown
                    name={"Tribunal"}
                    options={[
                        { label: "détaillé", value: "week" },
                        { label: "mensuel", value: "monthly" },
                        { label: "annuel", value: "annual" },
                    ]}
                    value={tribunal}
                    onChange={(e) => setTribunal(e.target.value)}
                />
            </div>
            <hr className="m-4" />

            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro de RG"
                        set={setNumeroRG}
                        value={numeroRG}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro Portalis"
                        set={setPortalis}
                        value={portalis}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom et prénom"
                        set={setName}
                        value={name}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom de jeune fille"
                        set={setFirstLastName}
                        value={firstLastName}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Début du controle"
                        type="date"
                        set={setStartDate}
                        value={startDate}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Fin de controle"
                        type="date"
                        set={setEndDate}
                        value={endDate}
                        required={true}
                    />
                </div>
            </div>

            <hr className="m-4" />
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom du tuteur/mandataire"
                        set={setAgentName}
                        value={agentName}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Email du tuteur/mandataire"
                        type="email"
                        set={setAgentMail}
                        value={agentMail}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom du subrogé"
                        set={setSubRogeName}
                        value={subRogeName}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Email du subroge"
                        type="email"
                        set={setSubRogeMail}
                        value={subRogeMail}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex justify-end">
                <Button
                    type={"empty"}
                    name={"Enregistrer"}
                    action={() => {
                        history.push("/list_controles");
                    }}
                />
            </div>
        </div>
    );
};
