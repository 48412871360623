import React from "react";

export const Button = (props: {
    [x: string]: any;
    name?: any;
    action?: any;
    type?: any;
    id?: any;
    padding?: any;
    isVisible?: any;
}) => {
    let { name, action, type, id, padding, isVisible } = props;

    if (isVisible === undefined) {
        isVisible = true;
    }

    const className = () => {
        let cn =
            "max-h-12 rounded font-bold py-2 px-4 focus:outline-none focus:shadow-outline";

        switch (type) {
            case "empty":
                cn +=
                    " border-2 border-atroposPrimary text-atroposPrimary hover:border-atroposSecondary hover:text-atroposSecondary ";
                break;
            case "red":
                cn += "max-h-12 rounded bg-red-700 hover:bg-red-400 text-white";
                break;

            default:
                cn +=
                    "max-h-12 rounded bg-atroposPrimary hover:bg-atroposSecondary text-white";
                break;
        }

        return cn;
    };

    return (
        <>
            {isVisible && (
                <button
                    id={id}
                    onClick={action}
                    className={className()}
                    data-secret={props["data-secret"] && props["data-secret"]}
                >
                    {name}
                </button>
            )}
        </>
    );
};
