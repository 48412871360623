import React from "react";
import { useHistory, useParams } from "react-router";
import { Button } from "../../../../_shared/Button";
import { useAuth } from "../../Login/auth.context";
import { Comments } from "../Comments/Comments";
import { DepotCRG } from "../DepotCRG";

export const CRGDepot = () => {
    const { depot_id }: { depot_id: string } = useParams();
    const history = useHistory();
    const { currentUser } = useAuth();
    return (
        <div className="p-4">
            <div className="flex justify-between">
                <Button
                    type={"empty"}
                    name={"Retour"}
                    action={() => {
                        history.push("/list_depots");
                    }}
                />
                <div className="font-bold text-xl">
                    Baptiste MAGNETTE - 2014/1289/A - xxxx-x-xxx-xxxx{" "}
                    <p className="text-xs italic text-center">
                        Controle généré le : 09/10/2024
                    </p>
                </div>
                <div>PENDING</div>
            </div>
            <DepotCRG data={[]} />
            <div className="mt-4">
                <Comments />
            </div>
            {currentUser.roles[0] === "verificateur_crg" && (
                <div className="mt-12 mb-12 flex justify-end">
                    <div className="mr-2">
                        <Button
                            type={"red"}
                            name={"Demander une correction"}
                            action={() => {
                                console.log("");
                            }}
                        />
                    </div>
                    <Button
                        type={"empty"}
                        name={"Valider le CRG"}
                        action={() => {
                            console.log("");
                        }}
                    />
                </div>
            )}
        </div>
    );
};
