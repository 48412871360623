import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Accordeon } from "../../../_shared/Accordeon";
import { Button } from "../../../_shared/Button";
import { DefaultDropdown } from "../../../_shared/Dropdown";
import { InputForm } from "../../../_shared/InputField";
import DropZoneArea from "../Majeurs/Cloud/DropzoneArea";

const Depot = (props: { onClose: any }) => {
    const { onClose } = props;

    const checkInformation = () => {};
    const _onValidation = () => {
        checkInformation();
        onClose();
    };

    const [acceptedFile, setAcceptedFile] = useState([]);

    const onDropZone = (acceptedFiles: any) => {
        setCNI(acceptedFiles[0]);
        return;
    };

    const [cni, setCNI] = useState(null);

    return (
        <>
            <div className="flex justify-between">
                <div className="w-1/3 p-6">
                    <h3>Carte Nationale d'identité</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md text-center">
                        {cni ? (
                            <img
                                className="mr-2"
                                src={"/imgs/checked.png"}
                                alt="Logo"
                            />
                        ) : (
                            <DropZoneArea
                                onDrop={onDropZone}
                                accept={["application/pdf"]}
                            />
                        )}
                    </div>
                </div>
                <div className="w-1/3 p-6">
                    <h3>Carte Nationale d'identité</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea onDrop={onDropZone} />
                    </div>
                </div>
                <div className="w-1/3 p-6">
                    <h3>Carte Nationale d'identité</h3>
                    <div className="mt-6 p-6 bg-slate-200 rounded-md">
                        <DropZoneArea onDrop={onDropZone} />
                    </div>
                </div>
            </div>

            <div className="flex justify-end">
                <Button name={"Suivant"} action={() => _onValidation()} />
            </div>
        </>
    );
};

const Revenus = (props: { onClose: any }) => {
    const { onClose } = props;
    const checkInformation = () => {};

    const _onValidation = () => {
        checkInformation();
        onClose();
    };

    const [year, setYear] = useState("");
    const [revenus, setRevenus] = useState("");
    const [wealth, setWealth] = useState("");

    return (
        <>
            <div>
                <InputForm
                    name="Revenus N - 1"
                    type={"number"}
                    set={setRevenus}
                    value={revenus}
                    required={true}
                />
                <InputForm
                    name="Patrimoine financier"
                    type={"number"}
                    set={setWealth}
                    value={wealth}
                    required={true}
                />
                <DefaultDropdown
                    name={"Année"}
                    options={[
                        { label: "détaillé", value: "week" },
                        { label: "mensuel", value: "monthly" },
                        { label: "annuel", value: "annual" },
                    ]}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />
                <div className="mt-4 flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            </div>
        </>
    );
};

const Depenses = (props: { onClose: any }) => {
    const { onClose } = props;
    const checkInformation = () => {};

    const _onValidation = () => {
        checkInformation();
        onClose();
    };
    return (
        <>
            <div className="flex justify-end">
                <Button name={"Suivant"} action={() => _onValidation()} />
            </div>
        </>
    );
};

const ComptesBancaires = (props: { onClose: any }) => {
    const { onClose } = props;
    const checkInformation = () => {};

    const _onValidation = () => {
        checkInformation();
        console.log("CLOSE");
        onClose();
    };
    return (
        <>
            <div className="flex justify-end">
                <Button name={"Suivant"} action={() => _onValidation()} />
            </div>
        </>
    );
};

const DettesEtCreances = (props: { onClose: any }) => {
    const { onClose } = props;
    const checkInformation = () => {};

    const _onValidation = () => {
        checkInformation();
        onClose();
    };
    return (
        <>
            <div className="flex justify-end">
                <Button name={"Suivant"} action={() => _onValidation()} />
            </div>
        </>
    );
};

const Immobilier = (props: { onClose: any }) => {
    const { onClose } = props;
    const checkInformation = () => {};

    const _onValidation = () => {
        checkInformation();
        onClose();
    };
    return (
        <>
            <div className="flex justify-end">
                <Button name={"Suivant"} action={() => _onValidation()} />
            </div>
        </>
    );
};

export const DepotCRG = (props: { readOnly?: any; data?: any }) => {
    const { readOnly, data } = props;

    const [numeroRG, setNumeroRG] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const { addToast } = useToasts();

    const [isOpenDocuments, setIsOpenDocuments] = useState(false);
    const [isOpenRevenus, setIsOpenRevenus] = useState(false);
    const [isOpenExpense, setIsOpenExpenses] = useState(false);
    const [isOpenLivret, setIsOpenLivret] = useState(false);
    const [isOpenDebt, setIsOpenDebt] = useState(false);
    const [isOpenImmobilier, setIsOpenImmobilier] = useState(false);

    const [isValidatedDocuments, setIsValidatedDocuments] = useState(false);
    const [isValidatedRevenus, setIsValidatedRevenus] = useState(false);
    const [isValidatedDepenses, setIsValidatedDepenses] = useState(false);
    const [isValidatedLivret, setIsValidatedLivret] = useState(false);
    const [isValidatedDebts, setIsValidatedDebts] = useState(false);
    const [isValidatedImmo, setIsValidatedImmo] = useState(false);

    const [isValidatedForm, setIsValidatedForm] = useState(false);
    const [followID, setFollowID] = useState("");
    const close = (name: any, isSubmit = false) => {
        setIsOpenDocuments(false);
        setIsOpenRevenus(false);
        setIsOpenExpenses(false);
        setIsOpenLivret(false);
        setIsOpenDebt(false);
        setIsOpenImmobilier(false);

        switch (name) {
            case "documents":
                setIsOpenDocuments(!isOpenDocuments);
                if (isSubmit) {
                    setIsValidatedDocuments(true);
                }
                break;
            case "revenus":
                setIsOpenRevenus(!isOpenRevenus);
                if (isSubmit) {
                    setIsValidatedRevenus(true);
                }
                break;
            case "depenses":
                setIsOpenExpenses(!isOpenExpense);
                if (isSubmit) {
                    setIsValidatedDepenses(true);
                }
                break;
            case "livrets":
                setIsOpenLivret(!isOpenLivret);
                if (isSubmit) {
                    setIsValidatedLivret(true);
                }
                break;
            case "dettes":
                setIsOpenDebt(!isOpenDebt);
                if (isSubmit) {
                    setIsValidatedDebts(true);
                }
                break;
            case "immo":
                setIsOpenImmobilier(!isOpenImmobilier);
                if (isSubmit) {
                    setIsValidatedImmo(true);
                }
                break;
            case "finish":
                if (isSubmit) {
                    setIsValidatedImmo(true);
                }
        }
    };

    const generateRandomID = (length = 10) => {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }

        return result;
    };

    const validateForm = () => {
        if (
            isValidatedDocuments &&
            isValidatedRevenus &&
            isValidatedDepenses &&
            isValidatedLivret &&
            isValidatedDebts &&
            isValidatedImmo
        ) {
            setIsValidatedForm(true);
            let _id = generateRandomID();
            setFollowID(_id);
        } else {
            addToast("Toutes les sections doivent être remplis.", {
                appearance: "error",
            });
        }
    };
    return (
        <div className="w-100">
            {!data && (
                <header className="w-100 h-24 bg-atroposPrimary flex justify-between">
                    <div className="p-2">
                        <Link to={"/depot-crg"}>
                            <img
                                src="/imgs/LOGO-ATROPOS-BLANC-FOND-TRANSPARENT.png"
                                alt="LOGO"
                                height={100}
                                width={175}
                                className="p-2 cursor-pointer"
                            />
                        </Link>
                    </div>
                    <div className="text-white text-sm p-2">
                        <span className="block h-12 font-semibold">
                            05 33 06 77 77
                        </span>
                        <span className="block h-12 font-semibold">
                            contact@atropos-protection.fr
                        </span>
                    </div>
                </header>
            )}
            <div className="w-100 flex justify-center">
                <div className="w-4/5 mt-8">
                    {!isValidatedForm && (
                        <>
                            {!data && (
                                <>
                                    <h2 className="text-center text-atroposPrimary">
                                        Contrôle de comptes au service des
                                        majeurs protégés
                                    </h2>
                                    <hr className="m-4" />
                                </>
                            )}
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                    <InputForm
                                        name="Numéro de RG"
                                        set={setNumeroRG}
                                        value={numeroRG}
                                        required={true}
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                    <InputForm
                                        name="Numéro Portalis"
                                        set={setNumeroRG}
                                        value={numeroRG}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                    <InputForm
                                        name="Début de la période"
                                        type="date"
                                        set={setStartDate}
                                        value={startDate}
                                        required={true}
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                    <InputForm
                                        name="Fin de la période"
                                        type="date"
                                        set={setEndDate}
                                        value={endDate}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <Accordeon
                                name={"Documents"}
                                component={
                                    <Depot
                                        onClose={() => close("documents", true)}
                                    />
                                }
                                isOpen={isOpenDocuments}
                                setIsOpen={() => close("documents")}
                                isFullFilled={isValidatedDocuments}
                            />
                            <Accordeon
                                name={"Revenus"}
                                component={
                                    <Revenus
                                        onClose={() => close("revenus", true)}
                                    />
                                }
                                isOpen={isOpenRevenus}
                                setIsOpen={() => close("revenus")}
                                isFullFilled={isValidatedRevenus}
                            />
                            <Accordeon
                                name={"Dépenses"}
                                component={
                                    <Depenses
                                        onClose={() => close("depenses", true)}
                                    />
                                }
                                isOpen={isOpenExpense}
                                setIsOpen={() => close("depenses")}
                                isFullFilled={isValidatedDepenses}
                            />
                            <Accordeon
                                name={"Livret et compte bancaires"}
                                isOpen={isOpenLivret}
                                setIsOpen={() => close("livrets")}
                                component={
                                    <ComptesBancaires
                                        onClose={() => close("livrets", true)}
                                    />
                                }
                                isFullFilled={isValidatedLivret}
                            />
                            <Accordeon
                                name={"Dettes et créances"}
                                isOpen={isOpenDebt}
                                setIsOpen={() => close("dettes")}
                                isFullFilled={isValidatedDebts}
                                component={
                                    <DettesEtCreances
                                        onClose={() => close("dettes", true)}
                                    />
                                }
                            />
                            <Accordeon
                                name={"Immobilier"}
                                component={
                                    <Immobilier
                                        onClose={() => close("finish", true)}
                                    />
                                }
                                isOpen={isOpenImmobilier}
                                setIsOpen={() => close("immo")}
                                isFullFilled={isValidatedImmo}
                            />
                            {!data && (
                                <div className="mt-12 mb-12 flex justify-end">
                                    <Button
                                        type={"empty"}
                                        name={"Envoyer mes documents"}
                                        action={() => {
                                            validateForm();
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {isValidatedForm && (
                        <div className="flex justify-center">
                            <div className="w-1/2 text-center rounded-md">
                                <h3>
                                    <img
                                        src={"/imgs/checked.png"}
                                        alt="Success"
                                        className="mx-auto p-10"
                                        height={200}
                                        width={200}
                                    />
                                    Vos informations ont bien été envoyés aux
                                    vérificateurs de CRG.
                                </h3>
                                <p>
                                    Votre numéro de suivi est le suivant :{" "}
                                    {followID}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
