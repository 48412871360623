import React from "react";
import { Button } from "../../../../_shared/Button";

export const Comments = () => {
    return (
        <section className=" dark:bg-gray-900  antialiased">
            <div className="px-4">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">
                        Discussions (1)
                    </h2>
                    <Button
                        type={"empty"}
                        name={"Publier"}
                        action={() => {
                            console.log("uhi");
                        }}
                    />
                </div>

                <form className="border">
                    <div className="py-2 px-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                        <textarea
                            id="comment"
                            rows={4}
                            className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                            placeholder="Write a comment..."
                            required
                        ></textarea>
                    </div>
                </form>
                <article className="p-6 text-base bg-white rounded-lg dark:bg-gray-900">
                    <footer className="flex justify-between items-center mb-2">
                        <div className="flex items-center">
                            <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                                <img
                                    className="mr-2 w-6 h-6 rounded-full"
                                    src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"
                                    alt="Michael Gough"
                                />
                                Michael Gough
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                                <time title="February 8th, 2022">
                                    Feb. 8, 2022
                                </time>
                            </p>
                        </div>
                    </footer>
                    <p className="text-gray-500 dark:text-gray-400">
                        Very straight-to-point article. Really worth time
                        reading. Thank you! But tools are just the instruments
                        for the UX designers. The knowledge of the design tools
                        are as important as the creation of the design strategy.
                    </p>
                </article>
            </div>
        </section>
    );
};
