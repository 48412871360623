import React, { useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../../../../_shared/Button";
import { DefaultDropdown } from "../../../../_shared/Dropdown";
import { InputForm } from "../../../../_shared/InputField";
import { Comments } from "../Comments/Comments";

const StatusControle = {
    SUCCESS: "Terminé",
    PENDING: "En cours",
    FAILED: "A échoué",
    REVISION: "En attente de justificatif supplémentaire",
};

export const ReadControle = () => {
    const history = useHistory();

    const [agentName, setAgentName] = useState("");
    const [agentMail, setAgentMail] = useState("");

    const [subRogeName, setSubRogeName] = useState("");
    const [subRogeMail, setSubRogeMail] = useState("");

    const [tribunal, setTribunal] = useState(null);
    const [numeroRG, setNumeroRG] = useState("");
    const [portalis, setPortalis] = useState("");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [firstLastName, setFirstLastName] = useState("");
    const [name, setName] = useState("");

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <Button
                    type={"empty"}
                    name={"Retour"}
                    action={() => {
                        history.push("/list_controles");
                    }}
                />
                <div className="font-bold text-xl">
                    Baptiste MAGNETTE - 2014/1289/A - xxxx-x-xxx-xxxx{" "}
                    <p className="text-xs italic text-center">
                        Controle généré le : 09/10/2024
                    </p>
                </div>
                <div>{StatusControle["PENDING"]}</div>
            </div>
            <div className="mt-2">
                <DefaultDropdown
                    name={"Tribunal"}
                    options={[
                        { label: "détaillé", value: "week" },
                        { label: "mensuel", value: "monthly" },
                        { label: "annuel", value: "annual" },
                    ]}
                    value={tribunal}
                    onChange={(e) => setTribunal(e.target.value)}
                />
            </div>
            <hr className="m-4" />
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro de RG"
                        set={setNumeroRG}
                        value={numeroRG}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro Portalis"
                        set={setPortalis}
                        value={portalis}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom et prénom"
                        set={setName}
                        value={name}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Nom de jeune fille"
                        set={setFirstLastName}
                        value={firstLastName}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Début du controle"
                        type="date"
                        set={setStartDate}
                        value={startDate}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Fin de controle"
                        type="date"
                        set={setEndDate}
                        value={endDate}
                        required={true}
                    />
                </div>
            </div>{" "}
            <hr className="m-4" />
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Nom du tuteur/mandataire"
                        set={setAgentName}
                        value={agentName}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Email du tuteur/mandataire"
                        type="email"
                        set={setAgentMail}
                        value={agentMail}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Compte crée"
                        type="checkbox"
                        set={setSubRogeMail}
                        value={subRogeMail}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Nom du subrogé"
                        set={setSubRogeName}
                        value={subRogeName}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Email du subroge"
                        type="email"
                        set={setSubRogeMail}
                        value={subRogeMail}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Compte crée"
                        type="checkbox"
                        set={setSubRogeMail}
                        value={subRogeMail}
                        required={true}
                    />
                </div>
            </div>{" "}
            <hr className="m-4" />
            <div className="px-4">
                <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">
                    Dépot de dossier
                </h2>
                <div className="bg-white text-center font-bold h-16 p-4 border border-slate-950">
                    Aucun dossier n'a été déposé à ce jour.
                </div>
            </div>
            <hr className="m-4" />
            <Comments />
        </div>
    );
};
